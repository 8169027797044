import React, { useState, useEffect, useCallback } from 'react';
import { Form, Spinner, Table, Alert, Row, Col, Pagination, Modal, Button } from 'react-bootstrap';
import { format, subDays, subMonths, startOfWeek, startOfMonth } from 'date-fns';
import axios from 'axios';
import * as XLSX from 'xlsx';

export const route = "/mimo/transactions";
export const role = "MIMO";

function TransactionList() {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [dateFilter, setDateFilter] = useState('All');
    const [customStartDate, setCustomStartDate] = useState('');
    const [customEndDate, setCustomEndDate] = useState('');
    const [username, setUsername] = useState('');
    const [terminalId, setTerminalId] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [registerName, setRegisterName] = useState('');
    const [sortField, setSortField] = useState('TRANS_ID');
    const [sortOrder, setSortOrder] = useState('DESC');
    const [showModal, setShowModal] = useState(false);
    const [entries, setEntries] = useState('all');
    const [groupByRegister, setGroupByRegister] = useState(false);
    const limit = 10;

    // Fields to exclude from the export
    const excludedFields = [
        '1_00', '0_50', '0_25', '0_10', '0_05', '0_01',
        '1', '2', '5', '10', '20', '50', '100'
    ];

    const handleExportClick = () => {
        if (dateFilter === 'All') {
            setDateFilter('today');
        }
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleExport = async () => {
        try {
            let startDate, endDate;
            const today = new Date();
            switch (dateFilter) {
                case 'today':
                    startDate = endDate = format(today, 'yyyy-MM-dd');
                    break;
                case 'yesterday':
                    startDate = endDate = format(subDays(today, 1), 'yyyy-MM-dd');
                    break;
                case 'custom':
                    startDate = customStartDate;
                    endDate = customEndDate;
                    if (!startDate || !endDate) {
                        throw new Error('Custom date range requires both start and end dates');
                    }
                    break;
                default:
                    startDate = '1970-01-01';
                    endDate = format(new Date(), 'yyyy-MM-dd');
            }

            const startDateTime = `${startDate} 00:00:00`;
            const endDateTime = `${endDate} 23:59:59`;

            const orderByField = groupByRegister ? 'REGISTER_NAME' : sortField;
            const registerNameParam = registerName;

            const url = `${process.env.REACT_APP_SNOWFLAKE_URL}/mimo/transactions/1/${entries === 'all' ? '99999999999999999' : entries}/${startDateTime}/${endDateTime}?username=${username}&terminalId=${terminalId}&transactionId=${transactionId}&registerName=${registerNameParam}&sortField=${orderByField}&sortOrder=${sortOrder}`;

            const response = await axios.get(url);

            let data = response.data;

            if (!data || data.length === 0) {
                throw new Error('No data available for export');
            }

            data = data.map(transaction => {
                const filteredTransaction = {};
                for (let key in transaction) {
                    if (!excludedFields.includes(key)) {
                        filteredTransaction[key] = transaction[key];
                    }
                }
                return filteredTransaction;
            });

            if (!data.some(transaction => Object.keys(transaction).length > 0)) {
                throw new Error('No valid data available for export');
            }

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(data);

            XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');

            XLSX.writeFile(workbook, 'transactions.xlsx');
        } catch (error) {
            console.error('Error exporting transactions:', error);
            alert('Error exporting transactions: ' + error.message);
        }

        handleModalClose();
    };

    const fetchTransactions = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            let startDate, endDate;
            const today = new Date();
            switch (dateFilter) {
                case 'today':
                    startDate = endDate = format(today, 'yyyy-MM-dd');
                    break;
                case 'yesterday':
                    startDate = endDate = format(subDays(today, 1), 'yyyy-MM-dd');
                    break;
                case 'currentWeek':
                    startDate = format(startOfWeek(today), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'currentMonth':
                    startDate = format(startOfMonth(today), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'last3Days':
                    startDate = format(subDays(today, 3), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'last7Days':
                    startDate = format(subDays(today, 7), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'last15Days':
                    startDate = format(subDays(today, 15), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'last30Days':
                    startDate = format(subDays(today, 30), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'last3Months':
                    startDate = format(subMonths(today, 3), 'yyyy-MM-dd');
                    endDate = format(new Date(), 'yyyy-MM-dd');
                    break;
                case 'custom':
                    startDate = customStartDate;
                    endDate = customEndDate;
                    if (!startDate || !endDate) {
                        setError('Custom date range requires both start and end dates');
                        setLoading(false);
                        return;
                    }
                    break;
                default:
                    startDate = '1970-01-01';
                    endDate = format(new Date(), 'yyyy-MM-dd');
            }

            const startDateTime = `${startDate} 00:00:00`;
            const endDateTime = `${endDate} 23:59:59`;

            const fetchLimit = groupByRegister ? '99999999999999999' : limit;
            const orderByField = groupByRegister ? 'REGISTER_NAME' : sortField;

            const url = `${process.env.REACT_APP_SNOWFLAKE_URL}/mimo/transactions/${page}/${fetchLimit}/${startDateTime}/${endDateTime}?username=${username}&terminalId=${terminalId}&transactionId=${transactionId}&registerName=${registerName}&sortField=${orderByField}&sortOrder=${sortOrder}`;
            const response = await axios.get(url);
            setTransactions(response.data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [dateFilter, page, limit, customStartDate, customEndDate, username, terminalId, transactionId, registerName, sortField, sortOrder, groupByRegister]);

    useEffect(() => {
        fetchTransactions();
    }, [page, limit, dateFilter, customStartDate, customEndDate, username, terminalId, transactionId, registerName, groupByRegister, fetchTransactions]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleSort = (field) => {
        setSortField(field);
        setSortOrder('DESC');
    };

    const handleGroupByRegisterChange = (e) => {
        setGroupByRegister(e.target.checked);
        if (e.target.checked) {
            setDateFilter('today');
        }
    };

    useEffect(() => {
        if (groupByRegister) {
            fetchTransactions();
        }
    }, [groupByRegister, fetchTransactions]);

    return (
        <div className="container mt-5">
            <h1>Transaction List</h1>
            <Button className="mb-3" variant="success" onClick={handleExportClick}>
                Export to Excel
            </Button>

            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Export Transactions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formEntries">
                        <Form.Label>Number of Entries</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter number of entries or 'all'"
                            value={entries}
                            onChange={(e) => setEntries(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleExport}>
                        Export
                    </Button>
                </Modal.Footer>
            </Modal>
            <Form>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="dateFilter">
                            <Form.Label>Date Filter</Form.Label>
                            <Form.Select value={dateFilter} onChange={(e) => setDateFilter(e.target.value)}>
                                <option value="All">All</option>
                                <option value="today">Today</option>
                                <option value="yesterday">Yesterday</option>
                                <option value="currentWeek">Current Week</option>
                                <option value="currentMonth">Current Month</option>
                                <option value="last3Days">Last 3 Days</option>
                                <option value="last7Days">Last 7 Days</option>
                                <option value="last15Days">Last 15 Days</option>
                                <option value="last30Days">Last 30 Days</option>
                                <option value="last3Months">Last 3 Months</option>
                                <option value="custom">Custom</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    {dateFilter === 'custom' && (
                        <>
                            <Col md={4}>
                                <Form.Group controlId="customStartDate">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control type="date" value={customStartDate} onChange={(e) => setCustomStartDate(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="customEndDate">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control type="date" value={customEndDate} onChange={(e) => setCustomEndDate(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </>
                    )}
                </Row>
                <Row className="mb-3">
                    <Col md={3}>
                        <Form.Group controlId="usernameFilter">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="terminalIdFilter">
                            <Form.Label>Terminal ID</Form.Label>
                            <Form.Control type="text" value={terminalId} onChange={(e) => setTerminalId(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="transactionIdFilter">
                            <Form.Label>Transaction ID</Form.Label>
                            <Form.Control type="text" value={transactionId} onChange={(e) => setTransactionId(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="registerNameFilter">
                            <Form.Label>Register Name</Form.Label>
                            <Form.Control type="text" value={registerName} onChange={(e) => setRegisterName(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={3}>
                        <Form.Group controlId="formGroupByRegister">
                            <Form.Check
                                type="checkbox"
                                label="Group by Register Name"
                                checked={groupByRegister}
                                onChange={handleGroupByRegisterChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            {loading ? (
                <Spinner animation="border" variant="primary" />
            ) : error ? (
                <Alert variant="danger">{error}</Alert>
            ) : transactions.length === 0 ? (
                <Alert variant="info">No transactions found</Alert>
            ) : (
                <>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('TRANS_ID')}>Transaction ID</th>
                                <th onClick={() => handleSort('TERMINAL_ID')}>Terminal ID</th>
                                <th onClick={() => handleSort('REGISTER_NAME')}>Register Name</th>
                                <th onClick={() => handleSort('USER_NAME')}>User Name</th>
                                <th>Transaction Name</th>
                                <th>Transaction Total</th>
                                <th>Date and Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map((transaction) => (
                                <tr key={transaction.TRANS_ID}>
                                    <td>{transaction.TRANS_ID}</td>
                                    <td>{transaction.TERMINAL_ID}</td>
                                    <td>{transaction.REGISTER_NAME}</td>
                                    <td>{transaction.USER_NAME}</td>
                                    <td>{transaction.TRANS_NAME}</td>
                                    <td>${transaction.TRANS_TOTAL.toFixed(2)}</td>
                                    <td>{transaction.TRANSACTION_DATETIME}</td> {/* Updated to display formatted datetime directly */}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination>
                        <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page === 1} />
                        <Pagination.Item active>{page}</Pagination.Item>
                        <Pagination.Next onClick={() => handlePageChange(page + 1)} disabled={transactions.length < limit} />
                    </Pagination>
                </>
            )}
        </div>
    );
}

export default TransactionList;
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LocationPicker from '../../components/LocationPicker'

import 'leaflet/dist/images/layers-2x.png'
import 'leaflet/dist/images/layers.png'
import 'leaflet/dist/images/marker-icon-2x.png'
import 'leaflet/dist/images/marker-icon.png'
import 'leaflet/dist/images/marker-shadow.png'

export const route = "/it/device/edit/:deviceId";
export const role = "IT";

function EditDevice() {
    const { deviceId } = useParams();
    const [deviceData, setDeviceData] = useState({
        type: '',
        friendlyName: '',
        name: '',
        terminalId: '',
        profileId: '',
        location: '',
        ip: '',
        isSpare: false
    });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDeviceDetails = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/${deviceId}/`);
            const data = await response.json();
            if (response.ok) {
                setDeviceData(data);
            } else {
                console.error('Failed to fetch device details');
            }
        };

        fetchDeviceDetails();
    }, [deviceId]);

    const handleChange = (e) => {
        setDeviceData({ ...deviceData, [e.target.name]: e.target.value });
    };

    const handleLocationChange = (latlng) => {
        setDeviceData({ ...deviceData, location: { lat: latlng.lat, lng: latlng.lng } });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/${deviceId}/`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(deviceData)
            });

            if (response.ok) {
                navigate('/it/device/list'); // Redirect to device list after successful update
            } else {
                alert('Failed to update device');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container mt-3">
            <h2>Edit Device</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="type" className="form-label">Type</label>
                    <select name="type" value={deviceData.type} onChange={handleChange} className="form-select">
                        <option value="">Select a Type</option>
                        <option value="Agilysys POS">Agilysys POS</option>
                        <option value="Gatemaster POS">Gatemaster POS</option>
                        <option value="Ticket Reader">Ticket Reader</option>
                        <option value="Kitchen Printer">Kitchen Printer</option>
                        <option value="Sign Player">Sign Player</option>
                        <option value="Access Point">Access Point</option>
                        {/* <option value="Switch">Switch</option> */}
                    </select>
                </div>
                <div className="mb-3">
                    <label className="form-label">Friendly Name</label>
                    <input type="text" className="form-control" name="friendlyName" value={deviceData.friendlyName} onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input type="text" className="form-control" name="name" value={deviceData.name} onChange={handleChange} />
                </div>
                {deviceData.type === 'Agilysys POS' && (
                    <>
                        <div className="mb-3">
                            <label className="form-label">Terminal ID</label>
                            <input type="text" className="form-control" name="terminalId" value={deviceData.terminalId} onChange={handleChange} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Profile ID</label>
                            <input type="text" className="form-control" name="profileId" value={deviceData.profileId} onChange={handleChange} />
                        </div>
                        <label htmlFor="type" className="form-label">Is Spare</label>
                        <select name="isSpare" value={deviceData.isSpare} onChange={handleChange} className="form-select">
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </>
                )}
                {deviceData.type === 'Ticket Reader' && (
                    <>
                        <label htmlFor="type" className="form-label">Is Spare</label>
                        <select name="isSpare" value={deviceData.isSpare} onChange={handleChange} className="form-select">
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </>
                )}
                {deviceData.type === 'Sign Player' && (
                    <>
                        <label htmlFor="type" className="form-label">Is Spare</label>
                        <select name="isSpare" value={deviceData.isSpare} onChange={handleChange} className="form-select">
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </>
                )}
                <div className="mb-3">
                    <label className="form-label">Location</label>
                    {deviceData && deviceData.location && deviceData.location.lat && deviceData.location.lng ? (
                        <LocationPicker initialPosition={{ lat: deviceData.location.lat, lng: deviceData.location.lng }} onLocationSelected={handleLocationChange} />
                    ) : (
                        <p>Loading location data...</p> // Or some loading spinner
                    )}
                </div>
                <label htmlFor="type" className="form-label">Pier</label>
                <select name="pier" value={deviceData.pier} onChange={handleChange} className="form-select">
                    <option value="">Select a pier</option>
                    <option value="Mariner's Pier">Mariner's Pier</option>
                    <option value="Surfside Pier">Surfside Pier</option>
                    <option value="Adventure Pier">Adventure Pier</option>
                </select>
                <div className="mb-3">
                    <label className="form-label">IP</label>
                    <input type="text" className="form-control" name="ip" value={deviceData.ip} onChange={handleChange} />
                </div>
                <button type="submit" className="btn mb-5 btn-primary">Update Device</button>
            </form>
        </div>
    );
}

export default EditDevice;
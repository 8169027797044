import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../CSS/Directory.module.css'; // Import the CSS module

export const route = '/it';
export const role = 'IT';

const ITDirectory = () => {
  const pages = [
    { name: 'Device List', path: '/it/device/list' },
    { name: 'Micros List', path: '/it/micros/list' },
    { name: 'Spare List', path: '/it/spare/list' },
    { name: 'Ping Device', path: '/it/device/ping' },
    { name: 'Report Issue', path: '/it/report' },
    { name: 'Configure New Ticket Reader', path: '/it/device/configure' },
    { name: 'Inventory', path: '/inventory' },
    { name: 'Boca Cleaning', path: '/boca/schedule' },
    { name: 'Air Fiber', path: '/it/airfiber' },
    { name: 'TV Display', path: '/tv/offline-devices' },
    { name: 'Winterization Checklist', path: '/it/device/winterization' },
  ];

  return (
    <div className={`container ${styles.directoryContainer} mt-5`}>
      <h1 className="text-center mb-5">IT Directory</h1>
      <div className="row">
        {pages.map((page, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className={`card shadow-sm ${styles.card}`}>
              <div className="card-body">
                <h5 className="card-title">{page.name}</h5>
                <Link to={page.path} className={`btn btn-primary w-100 ${styles.button}`}>
                  Go to {page.name}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ITDirectory;
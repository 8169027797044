import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, Button, Form, Pagination, Spinner, InputGroup, FormControl, Modal } from 'react-bootstrap';
import { debounce } from 'lodash';
import { format } from 'date-fns';

export const route = "/agilysys/assc";
export const role = "Agilysys";

const AgilysysTransactionList = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [profitCenter, setProfitCenter] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [debouncedProfitCenter, setDebouncedProfitCenter] = useState(profitCenter);
    const [debouncedFirstName, setDebouncedFirstName] = useState(firstName);
    const [debouncedLastName, setDebouncedLastName] = useState(lastName);
    const [debouncedStartDate, setDebouncedStartDate] = useState(startDate);
    const [debouncedEndDate, setDebouncedEndDate] = useState(endDate);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [inputPage, setInputPage] = useState(page);

    const [showModal, setShowModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    // Cache to store pages
    const cache = React.useRef({});

    // Debounce input changes for better performance
    useEffect(() => {
        const debounceHandler = debounce(() => {
            setDebouncedProfitCenter(profitCenter);
            setDebouncedFirstName(firstName);
            setDebouncedLastName(lastName);
            setDebouncedStartDate(startDate);
            setDebouncedEndDate(endDate);
            setPage(1); // Reset to first page when filters change
            setInputPage(1);
            cache.current = {}; // Clear cache on filter change
        }, 300);

        debounceHandler();

        return () => {
            debounceHandler.cancel();
        };
    }, [profitCenter, firstName, lastName, startDate, endDate]);

    const fetchPageData = useCallback(async (pageToFetch) => {
        const defaultStartDate = '1970-01-01';
        const defaultEndDate = new Date().toISOString().split('T')[0];

        // Check if the requested page data is in cache
        if (cache.current[pageToFetch]) {
            return cache.current[pageToFetch];
        }

        if (page === pageToFetch) {
            setLoading(true);
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_SNOWFLAKE_URL}/api/agilysys/assc/${pageToFetch}/${limit}`, {
                params: {
                    startDate: debouncedStartDate || defaultStartDate,
                    endDate: debouncedEndDate || defaultEndDate,
                    profitCenter: debouncedProfitCenter,
                    firstName: debouncedFirstName,
                    lastName: debouncedLastName,
                }
            });

            const transactions = response.data.transactions?.data || [];
            const totalRecords = response.data.transactions.totalRecords || 0;
            const calculatedTotalPages = Math.max(Math.ceil(totalRecords / limit), 1);
            setTotalPages(calculatedTotalPages);

            // Cache the fetched data
            cache.current[pageToFetch] = transactions;

            return transactions;
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        } finally {
            setLoading(false);
        }
    }, [page, debouncedStartDate, debouncedEndDate, debouncedProfitCenter, debouncedFirstName, debouncedLastName, limit]);

    const fetchData = useCallback(async () => {
        const currentPageData = await fetchPageData(page);
        setData(currentPageData);

        // Pre-fetch next and previous pages if applicable
        if (page < totalPages) {
            fetchPageData(page + 1);
        }
        if (page > 1) {
            fetchPageData(page - 1);
        }
    }, [page, fetchPageData, totalPages]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
            setInputPage(newPage);
        }
    };

    const handlePageInputChange = (e) => {
        const value = e.target.value;
        if (value === '') {
            setInputPage('');
            return;
        }
        const pageNumber = Number(value);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
            setInputPage(pageNumber);
        }
    };

    const handlePageInputBlur = () => {
        if (inputPage !== page && inputPage !== '') {
            handlePageChange(inputPage);
        } else {
            setInputPage(page);
        }
    };

    const handleShowDetails = (transaction) => {
        setSelectedTransaction(transaction);
        setShowModal(true);
    };

    return (
        <div className="container mt-4">
            <h2>Agilysys Associate Pass Usage</h2>
            <Form>
                <Form.Group>
                    <Form.Label>Profit Center</Form.Label>
                    <Form.Control type="text" value={profitCenter} onChange={(e) => setProfitCenter(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control type="date" value={startDate} onChange={(e) => {
                        setStartDate(e.target.value);
                        setPage(1); // Reset to page 1
                    }} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control type="date" value={endDate} onChange={(e) => {
                        setEndDate(e.target.value);
                        setPage(1); // Reset to page 1
                    }} />
                </Form.Group>
                <Button onClick={() => fetchData()}>Search</Button>
            </Form>

            {loading ? (
                <div className="d-flex justify-content-center mt-4">
                    <Spinner animation="border" role="status" />
                </div>
            ) : (
                <>
                    <Table striped bordered hover className="mt-4">
                        <thead>
                            <tr>
                                <th>Check Number</th>
                                <th>Date/Time</th>
                                <th>Profit Center</th>
                                <th>Total</th>
                                <th>Employee First Name</th>
                                <th>Employee Last Name</th>
                                <th>User ID</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((transaction) => (
                                <tr key={transaction.CHECKNUMBER}>
                                    <td>{transaction.CHECKNUMBER}</td>
                                    <td>{format(new Date(transaction.TENDEREDDATETIME), 'MMMM d, yyyy, h:mm a')}</td>
                                    <td>{transaction.PROFITCENTERNAME}</td>
                                    <td>${(transaction.CHECKGROSSREV + transaction.TAXAMOUNT + transaction.TIPAMOUNT - transaction.DISCOUNTAMOUNT).toFixed(2)}</td>
                                    <td>{transaction.EMPFIRSTNAME}</td>
                                    <td>{transaction.EMPLASTNAME}</td>
                                    <td>{transaction.LNGUSERID}</td>
                                    <td>
                                        <Button variant="info" onClick={() => handleShowDetails(transaction)}>
                                            More Details
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {totalPages > 1 && (
                        <div className="d-flex justify-content-between align-items-center">
                            <Pagination>
                                <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page === 1} />
                                <InputGroup>
                                    <FormControl
                                        type="number"
                                        value={inputPage}
                                        onChange={handlePageInputChange}
                                        onBlur={handlePageInputBlur}
                                        min={1}
                                        max={totalPages}
                                        style={{ width: '60px', textAlign: 'center' }}
                                    />
                                </InputGroup>
                                <Pagination.Next onClick={() => handlePageChange(page + 1)} disabled={page === totalPages} />
                            </Pagination>
                            <div>
                                Page {page} of {totalPages}
                            </div>
                        </div>
                    )}

                    {/* Modal for More Details */}
                    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Transaction Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {selectedTransaction && (
                                <>
                                    <p><strong>Check Number:</strong> {selectedTransaction.CHECKNUMBER}</p>
                                    <p><strong>Date/Time:</strong> {format(new Date(selectedTransaction.TENDEREDDATETIME), 'MMMM d, yyyy, h:mm a')}</p>
                                    <p><strong>Profit Center:</strong> {selectedTransaction.PROFITCENTERNAME}</p>
                                    <p><strong>Gross Revenue:</strong> ${selectedTransaction.CHECKGROSSREV.toFixed(2)}</p>
                                    <p><strong>Tax Amount:</strong> ${selectedTransaction.TAXAMOUNT.toFixed(2)}</p>
                                    <p><strong>Tip Amount:</strong> ${selectedTransaction.TIPAMOUNT.toFixed(2)}</p>
                                    <p><strong>Discount Amount:</strong> ${selectedTransaction.DISCOUNTAMOUNT.toFixed(2)}</p>
                                    <p><strong>Total:</strong> ${(selectedTransaction.CHECKGROSSREV + selectedTransaction.TAXAMOUNT + selectedTransaction.TIPAMOUNT - selectedTransaction.DISCOUNTAMOUNT).toFixed(2)}</p>
                                    <p><strong>Employee First Name:</strong> {selectedTransaction.EMPFIRSTNAME}</p>
                                    <p><strong>Employee Last Name:</strong> {selectedTransaction.EMPLASTNAME}</p>
                                    <p><strong>User ID:</strong> {selectedTransaction.LNGUSERID}</p>
                                    <hr />
                                    <h5>Ordered Items:</h5>
                                    <Table striped bordered hover className="mt-3">
                                        <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedTransaction.ITEMS.split(', ').map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item}</td>
                                                    <td>${selectedTransaction.ITEMPRICES.split(', ')[index]}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )}
        </div>
    );
};

export default AgilysysTransactionList;
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../CSS/TVDisplay.css';

export const route = "/tv/offline-devices";
export const role = ""; // No role required for this page

const TVDisplay = () => {
    const [offlineDevices, setOfflineDevices] = useState([]);

    useEffect(() => {
        const fetchOfflineDevices = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/`);
            const data = await response.json();
            if (response.ok) {
                const offline = data.filter(device => {
                    if (device.isOnline) {
                        return false;
                    }

                    if (device.isSpare) {
                        return false
                    };

                    return true
                });


                setOfflineDevices(offline);
            } else {
                console.error('Failed to fetch devices');
            }
        };

        fetchOfflineDevices();

        const interval = setInterval(fetchOfflineDevices, 30000); // Refresh every 30 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <Container fluid className="tv-display-container">
            <h1 className="text-center mt-3">Offline Devices</h1>
            <Row>
                {offlineDevices.length > 0 ? (
                    offlineDevices.map((device) => (
                        <Col key={device._id} xs={6} md={4} lg={3} className="mb-4">
                            <div className={`device-card ${device.isSpare ? 'spare-device' : ''}`}>
                                <h5>{device.friendlyName}</h5>
                                <p><strong>Type:</strong> {device.type}</p>
                                <p><strong>Pier:</strong> {device.pier}</p>
                                <p><strong>IP:</strong> {device.ip}</p>
                                {device.isSpare && <p className="text-warning">Spare Device</p>}
                            </div>
                        </Col>
                    ))
                ) : (
                    <p className="text-center">All devices are online.</p>
                )}
            </Row>
        </Container>
    );
};

export default TVDisplay;